import { Select } from '@leland-dev/leland-ui-library';
import { type FC, useState } from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';

import { type PhoneNumber } from '../../__generated-gql-types__/globalTypes';
import Input from '../inputs/Input';

interface CountryInformation {
  countryName: string;
  callingCountryCode: string;
}

export const SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO: Record<
  string,
  CountryInformation
> = Object.freeze({
  US: {
    countryName: 'United States',
    callingCountryCode: '1',
  },
  CA: {
    countryName: 'Canada',
    callingCountryCode: '1',
  },
  DE: {
    countryName: 'Germany',
    callingCountryCode: '49',
  },
  IN: {
    countryName: 'India',
    callingCountryCode: '91',
  },
  GB: {
    countryName: 'United Kingdom',
    callingCountryCode: '44',
  },
  // China restricts automated SMS
  // see https://www.twilio.com/en-us/guidelines/cn/sms#:~:text=Twilio%20is%20currently%20unable%20to,legitimate%20and%20registered%20customer%20traffic
  // CN: {
  //   countryName: 'China',
  //   callingCountryCode: '86',
  // },
});

interface PhoneNumberInputProps {
  defaultValues?: Nullable<PhoneNumber>;
  register?: UseFormRegisterReturn;
  onCountrySelect: (value: string) => void;
  onPhoneNumberChange?: (value: string) => void;
}

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  defaultValues,
  register,
  onCountrySelect,
  onPhoneNumberChange,
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState<
    Nullable<string>
  >(defaultValues?.countryCode ?? null);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    defaultValues?.nationalFormat
      ? defaultValues.nationalFormat.replace(' ', '')
      : '',
  );

  return (
    <div className="mt-6 flex w-full flex-col rounded-md border border-leland-gray-stroke transition duration-100 focus-within:border-leland-gray-extra-light">
      <div className="py-1">
        <Select
          borderless
          value={selectedCountryCode ?? undefined}
          placeholder="Select your country"
          options={Object.keys(SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO).map(
            (countryCode) => ({
              label: `${SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO[countryCode].countryName} +${SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO[countryCode].callingCountryCode}`,
              value: countryCode,
            }),
          )}
          onValueChange={(value: string) => {
            onCountrySelect(value);
            setSelectedCountryCode(value);
          }}
        />
      </div>
      <Input
        className="w-full border-t py-1"
        inputContainerClassName="w-full outline-none focus:outline-none border-0 rounded-r-none"
        label="Phone number"
        labelIsHidden
        subtlePrefix={
          selectedCountryCode
            ? `+${SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO[selectedCountryCode]?.callingCountryCode}`
            : undefined
        }
        placeholder="XXX-XXX-XXXX"
        type="tel"
        value={phoneNumber}
        register={register}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
          onPhoneNumberChange?.(e.target.value);
        }}
      />
    </div>
  );
};
